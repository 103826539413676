@import 'node_modules/@next-insurance/ni-material/styles/variables-rebrand';
@import '../abstracts/mixins';

/* stylelint-disable font-family-no-missing-generic-family-keyword -- init Stylelint. */

.ni-loader-wrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1010;
  height: 100%;
  background: var(--white);

  &.light-gray {
    background-color: var(--gray-15);
  }

  .transparent & {
    background-color: var(--gray-50);
  }

  .no-background & {
    background-color: transparent;
  }

  .mask-spinner-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .smart-agent-animation-wrapper {
    text-align: center;
  }

  .smart-agent-animation-wrapper,
  .quote-animation-wrapper {
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include on-desktop {
      margin-top: 180px;
    }

    .animation-element {
      padding: var(--ni-3xl-spacing);
    }
  }

  .modal-animation-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    margin-top: 220px;

    @include on-desktop {
      margin-top: 380px;
    }

    .animation-element {
      padding: var(--ni-3xl-spacing);
    }
  }

  .circle-modal-shell {
    .quote-status-modal {
      margin-top: 300px;
      align-items: center;
      display: flex;
      flex-direction: column;

      a.logo {
        height: 78px;
        width: 100%;
        margin: 0 60px;
        background: url('/assets/images/next-logo/next-blue-logo.svg') no-repeat center;
        background-size: 107px 40px;

        @include on-desktop {
          background: url('/assets/images/next-logo/next-blue-logo.svg') no-repeat center;
          background-size: 156px 72px;
        }
      }

      .bar-loader-wrapper {
        margin-top: 10px;
        background-color: var(--gray-25);
        border-radius: 7px;
        width: 250px;

        @include on-desktop {
          width: 400px;
          margin-top: 25px;
        }

        .bar-loader {
          height: 12px;
          border-radius: 7px;
          background-image: repeating-linear-gradient(
            -45deg,
            var(--next-blue-50),
            var(--next-blue-50) 11px,
            var(--next-blue) 10px,
            var(--next-blue) 20px /* determines size */
          );
          background-size: 28px 28px;
          animation: move 3s linear;
        }

        @keyframes move {
          0% {
            background-position: 0 0;
            width: 0;
          }

          25% {
            width: 25%;
          }

          50% {
            width: 50%;
          }

          75% {
            width: 75%;
          }

          100% {
            background-position: 28px 0;
            width: 100%;
          }
        }
      }
    }
  }
}

.loading.full-screen.transparent {
  background-color: transparent;
}

.loading-modal {
  z-index: 1103;
  position: absolute;
}
