@import 'node_modules/ngx-toastr/toastr.css';
@import 'node_modules/@next-insurance/ni-material/styles/variables-rebrand';
@import 'apps/shell/styles/abstracts/mixins';

@keyframes slide-in-from-below {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.ngx-toastr {
  &.toast-success {
    animation: slide-in-from-below 0.5s ease-out;
    color: var(--black);
    background-color: var(--jade-30);
    border-left: 8px solid var(--jade);
    border-radius: $border-radius-super-curvy;
    box-shadow: none;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTYiIGN5PSIxNiIgcj0iMTIiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTYuMDAwMSAyOC4wMDUxQzIyLjYzMDMgMjguMDA1MSAyOC4wMDUxIDIyLjYzMDMgMjguMDA1MSAxNi4wMDAxQzI4LjAwNTEgOS4zNjk5NCAyMi42MzAzIDMuOTk1MTIgMTYuMDAwMSAzLjk5NTEyQzkuMzY5OTQgMy45OTUxMiAzLjk5NTEyIDkuMzY5OTQgMy45OTUxMiAxNi4wMDAxQzMuOTk1MTIgMjIuNjMwMyA5LjM2OTk0IDI4LjAwNTEgMTYuMDAwMSAyOC4wMDUxWk0yMS4zNTcyIDEzLjUwOThDMjEuNzQ3NyAxMy4xMTkzIDIxLjc0NzcgMTIuNDg2MiAyMS4zNTcyIDEyLjA5NTZDMjAuOTY2NyAxMS43MDUxIDIwLjMzMzUgMTEuNzA1MSAxOS45NDMgMTIuMDk1NkwxNC4xMjg3IDE3LjkwOTlMMTEuOTYzOSAxNS43NDUxQzExLjU3MzQgMTUuMzU0NiAxMC45NDAzIDE1LjM1NDYgMTAuNTQ5NyAxNS43NDUxQzEwLjE1OTIgMTYuMTM1NyAxMC4xNTkyIDE2Ljc2ODggMTAuNTQ5NyAxNy4xNTk0TDEzLjQ0MDMgMjAuMDQ5OUMxMy44MzA4IDIwLjQ0MDQgMTQuNDYzOSAyMC40NDA0IDE0Ljg1NDUgMjAuMDQ5OUMxNC45MTAzIDE5Ljk5NDEgMTQuOTU4MSAxOS45MzM0IDE0Ljk5NzkgMTkuODY5MUwyMS4zNTcyIDEzLjUwOThaIiBmaWxsPSIjMDA4MzVGIi8+Cjwvc3ZnPg==');
  }
}

.toast-container.toast-bottom-center {
  .ngx-toastr {
    width: fit-content;
  }
}

.toast-container.toast-bottom-center-above-footer {
  .ngx-toastr {
    @include on-desktop {
      width: max-content;
    }
  }
}

.toast-bottom-center-above-footer {
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);

  @include on-desktop {
    bottom: 120px;
  }
}
